<template>
  <div class="container h-full flex align-center justify-center">
  <!-- main:  {{mainData}} -->
    <div class="bg-white rounded-2xl p-4 shadow sign-wrapper">
      <h1 class="text-2xl tracking-wider text-gray-600">{{ $t("sign_in") }}</h1>
      <div class="flex py-4">
        <div class="flex-1 mr-4 space-y-2">
          <v-text-field
            append-icon="mdi-email-outline"
            type="text"
             :label="$t('Email')"
            v-model="formValues.email"
          ></v-text-field>
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Password')"
            v-model="formValues.password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <!--          <div class="flex align-center">-->
          <!--            <div class="remember-me flex-1">-->
          <!--              <v-checkbox-->
          <!--                v-model="formValues.rememberMe"-->
          <!--                class="mt-0"-->
          <!--                label="Keep me logged in"-->
          <!--                type="checkbox"-->
          <!--                hide-details-->
          <!--              ></v-checkbox>-->
          <!--            </div>-->
          <!--            <div class="flex-none">-->
          <!--              <router-link to="/">Forgot Password!</router-link>-->
          <!--            </div>-->
          <!--          </div>-->
          <v-btn color="primary" depressed @click.prevent="signIn"
            >{{ $t("sign_in") }}
          </v-btn>
        </div>
        <div class="flex-none hidden-sm-and-down ml-8">
          <img src="@/assets/images/home-banner.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      showPassword: false,
      formValues: {
        email: "",
        password: "",
      },
    };
  },
 
  computed:{
    mainData(){
      return this.$store.state.common.main;
    }
  },
  methods: {
    signIn() {
      this.login(this.formValues).then(() => {
        this.$router.push({ name: "home" });
      });
    },
    ...mapActions("account", ["login"]), 
  },
};
</script>
<style lang="scss">
.sign-wrapper {
  width: 700px;
}
</style>
